/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import { FaPlay } from "react-icons/fa";
import { TbPlayerPauseFilled } from "react-icons/tb";
import { Base } from '../../Baseurl/Baseurl';
import axios from 'axios';
import "swiper/css";
import "swiper/css/navigation";
import "./NewGallery.css";
import { useNavigate } from "react-router-dom";

export default function App() {
  const swiperRef = useRef(null); 
  const [currentSlide, setCurrentSlide] = useState(1); 
  const [isPlaying, setIsPlaying] = useState(true); 
  const [url, setUrl] = useState([]); // Store the gallery data
  const navigate = useNavigate();

  const [url2, setUrl2] = useState([
    { images: "./assets/img/my/longtypebold.png" },
  ]);

  // Function to fetch gallery images
  const GalleryImages = () => {
    axios.get(`${Base}/api/user/view/gallery`)
      .then((e) => {
        // On successful API call, set data to state
        setUrl(e?.data?.data);
        localStorage.setItem("galleryImages", JSON.stringify(e?.data?.data)); // Store data in localStorage
      })
      .catch((err) => {
        console.log(err); // Log errors if any
      });
  };

  // useEffect hook to run once when component is mounted
  useEffect(() => {
    // First check if data is already available in localStorage
    const storedData = localStorage.getItem("galleryImages");
    if (storedData) {
      // If data exists in localStorage, set it to state
      setUrl(JSON.parse(storedData));
    } 
      // If no data in localStorage, make an API call to fetch data
      GalleryImages();
    
  }, []);

  // Swiper controls
  const handlePrev = () => {
    if (swiperRef.current?.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current?.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const toggleAutoplay = () => {
    if (swiperRef.current?.swiper) {
      const swiperInstance = swiperRef.current.swiper;
      if (isPlaying) {
        swiperInstance.autoplay.stop(); 
      } else {
        swiperInstance.autoplay.start(); 
      }
      setIsPlaying(!isPlaying); 
    }
  };

  return (
    <div className="mainn">
      <Swiper
        ref={swiperRef}
        onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex + 1)}
        className="mySwiper mySwiperNewGallery my-5"
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]} 
      >
        {(url.length > 0 ? url : url2).map((e, index) => (
          <SwiperSlide
            key={index}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "black",
              zIndex: "9999"
            }}
          >
            <img
              onClick={() => navigate('/gallery')}
              className="imgg"
              src={e.images}
              alt=""
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Navigation Buttons */}
      <div className="btn1">
        <button
          onClick={handlePrev}
          style={{
            border: "none",
            color: "white",
            fontWeight: "bold",
            fontSize: "20px",
            cursor: "pointer",
            backgroundColor: "transparent"
          }}
        >
          <GrFormPrevious style={{ color: "white", backgroundColor: "transparent" }} />
        </button>
        <span style={{ color: "white", fontWeight: "bold" }}>
          {currentSlide} / {url.length}
        </span>
        <button
          onClick={handleNext}
          style={{
            padding: "10px",
            border: "none",
            color: "white",
            fontWeight: "bold",
            cursor: "pointer",
            fontSize: "20px",
            backgroundColor: "transparent"
          }}
        >
          <MdNavigateNext style={{ color: "white", backgroundColor: "transparent" }} />
        </button>
      </div>

      {/* Autoplay control button */}
      <div className="btn2">
        <button
          onClick={toggleAutoplay}
          style={{
            padding: "0px",
            border: "none",
            color: "white",
            fontWeight: "bold",
            cursor: "pointer",
            fontSize: "15px",
            backgroundColor: "transparent"
          }}
        >
          {isPlaying ? <TbPlayerPauseFilled /> : <FaPlay />}
        </button>
      </div>
    </div>
  );
}
