import axios from 'axios';
import React, { useState } from 'react'
import { Base } from '../../Baseurl/Baseurl';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const Message = () => {
    const [data, setData] = useState({
        fname: "",
        phone: "",
        email: "",
        msg: ""

    })
    const [loader, setloader] = useState(false)
    const handleData = (event) => {
        const { name, value } = event.target;
        setData((prev) => ({
            ...prev,
            [name]: value
        }))
        console.log(event.target.value)
    }
    const submitData = (event) => {
        setloader(true)
        const formdata = new FormData();
        formdata?.append('name', data?.fname)
        formdata?.append('phone_no', data?.phone)
        formdata?.append('email', data?.email)
        formdata?.append('message', data?.msg)

        event.preventDefault();
        axios.post(`${Base}/api/user/contact/store`, formdata).then((e) => {
            if (e.data) {
                toast.success(e.data.message)
                setData({
                    fname: "",
                    phone: "",
                    email: "",
                    msg: ""
                })
                setloader(false)
            }

        }).catch((err) => {
            console.log(err.message)
            setData({
                fname: "",
                phone: "",
                email: "",
                msg: ""
            })
        })

    }
    return (
        <div class="contact-area inner-padding2">
            <ToastContainer />

            <div class="container">
                <form onSubmit={submitData} method="POST" id="cf">
                    <h3 style={{color:"black"}}>LEAVE A MESSAGE</h3>
                    <div class="cf-msg"></div>
                    <div class="contact-form row">
                        <div class="contact-select col-12 col-md-6">
                            <div class="form-group">
                                <div class="input-group">
                                    <input value={data.fname} onChange={handleData} type="text" id="fname" name="fname" class="form-control" placeholder="Name *" />
                                </div>
                            </div>
                        </div>
                        <div class="contact-select col-12 col-md-6">
                            <div class="form-group">
                                <div class="input-group">
                                    <input value={data.phone} onChange={handleData} type="text" id="phone" class="form-control" name="phone" placeholder="Phone" />
                                </div>
                            </div>
                        </div>
                        <div class="contact-select col-12 col-md-6">
                            <div class="form-group">
                                <div class="input-group">
                                    <input value={data.email} onChange={handleData} type="email" class="form-control" id="email" name="email" placeholder="E-mail *" />
                                </div>
                            </div>
                        </div>
                        <div class="contact-select col-12 col-md-6">
                            <div class="form-group">
                                <div class="input-group mn">
                                    <textarea value={data.msg} onChange={handleData} rows="3" class="form-control form-message" placeholder="Write here" id="msg" name="msg"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="contact-select col-12 col-md-6">
                            <button type="submit" id="submit" name="submit" class="btn btn-default btn-submit">SEND  {loader ? 'MESSAGE...' : 'MESSAGE'}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Message