
const Banner = ({title}) => {
  return (
    <>
    {/* <div className="header-caption-inner" >
    <h1>{title}</h1>
    <p><a href="/"> Home / {title}</a></p>
</div> */}
{/* <div className="page-header" id="home">
        <div className="header-caption">
            <div className="header-caption-contant">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    </>
  )
}
export default Banner