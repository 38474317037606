/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import './styles.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Base } from '../../Baseurl/Baseurl';
import Cookies from 'js-cookie'; // Import Cookies to handle cookie operations

const LunchDinnerSlider = () => {
  const [dinner, setDinner] = useState([]);  
  const [dinner2, setDinner2] = useState([  
    { image: "./assets/img/my/longtypebold.png" },
    { image: "./assets/img/my/longtypebold.png" },
    { image: "./assets/img/my/longtypebold.png" },
    { image: "./assets/img/my/longtypebold.png" }
  ]);

  // Function to fetch data from API and set it in cookies
  const sliderImg = () => {
    axios.get(`${Base}/api/user/view_dinner_menu`)
      .then((e) => {
        setDinner(e?.data?.data); // Set fetched data to state
        Cookies.set('dinnerMenu', JSON.stringify(e?.data?.data), { expires: 1 }); // Store data in cookies for 7 days
      })
      .catch((err) => {
        console.log(err.message); // Log error if API call fails
      });
  };

  useEffect(() => {
    // Check if dinner data is already stored in cookies
    const storedData = Cookies.get('dinnerMenu');
    if (storedData) {
      setDinner(JSON.parse(storedData)); // If data exists in cookies, use it
    } 
      sliderImg(); // If no data in cookies, make an API call
    
  }, []);

  return (
    <div className='container my-5'>
      <div className="section-title-area">
        <h2 className="section-title" style={{ color: 'black' }}>Lunch & Dinner Menu</h2>
        <div className="section-divider" />
      </div>

      {
        dinner.length === 1 ? (
          <div>
            {(dinner.length > 0 ? dinner : dinner2).map((img, index) => {
              return (
                <div key={index} style={{
                  display: 'flex',
                  justifyContent: "center",
                  margin: "auto"
                }}>
                  <img src={img.image} alt="" style={{ width: '50%', margin: "auto" }} />
                </div>
              );
            })}
          </div>
        ) : (
          <div className='breakfast-slider'>
            {(dinner.length > 0 ? dinner : dinner2).map((img, index) => {
              return (
                <div key={index} style={{ margin: "auto" }}>
                  <img src={img.image} alt="" />
                </div>
              );
            })}
          </div>
        )
      }
    </div>
  );
};

export default LunchDinnerSlider;
