import React from 'react'
import Banner from '../../Components/Banner'
import TimmerSection from '../../Components/Event/TimmerSection'
import Layout from '../../Layout/Layout'
import HomebannerNew from '../../Components/Home/HomebannerNew'

const Event = () => {
    return (
               <Layout>
                <HomebannerNew />
                       <Banner title={"Upcoming Events"} />
                       <TimmerSection />
               </Layout>
      
    )
}

export default Event