/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import TimmerSection from '../Event/TimmerSection';
import GalleryImages from '../Gallery/GalleryImages';
import { useNavigate } from 'react-router-dom';
import HomeGallery from '../Gallery/HomeGallery';
import HomeGallery2 from '../Gallery/HomeGallery2';
const WelcomeToClub = () => {
const Navigation = useNavigate()

  return (
    <div className='position-relative'>

      {/* <div className="service-area inner-padding2 ">
        
        <div className="container">
          <div className="row">
            <div className="col-12 foo">
              <div className="section-title-area">
                <h2 className="section-title" style={{color:'black',paddingTop:"20px"}}>Welcome To Ole Brass Rail</h2>
                <div className="section-divider" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="service-item foo">
                <img src="assets/img/service-1.webp" alt="responsive img" />
                <div className="caption">
                  <div className="caption-inner">
                    <div className="caption-content">
                      <i className="fa fa-glass" />
                      <h3 className="service-title">drink bar</h3>
                      <p>
                        Your Destination for Sports, Drinks, Delicious Food, and Fun
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="service-item foo">
                <img src="assets/img/service-2.webp" alt="responsive img" />
                <div className="caption">
                  <div className="caption-inner">
                    <div className="caption-content">
                      <i className="fa fa-cutlery" />
                      <h3 className="service-title">restaurant</h3>
                      <p>
                        Your Destination for Sports, Drinks, Delicious Food, and Fun
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="service-item foo">
                <img src="assets/img/service-3.webp" alt="responsive img" />
                <div className="caption">
                  <div className="caption-inner">
                    <div className="caption-content">
                      <i className="fa fa-music" />
                      <h3 className="service-title">dj’s music</h3>
                      <p>
                        Your Destination for Sports, Drinks, Delicious Food, and Fun
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="about-area inner-padding pogochotabheem">
        <div className="container">
          <div className="row">
            <div className="col-12 foo">
              <div className="section-title-area-2">
                <h2 className="section-title" style={{color:'black',marginTop:'15px'}}>About Ole Brass Rail</h2>
                <div className="section-divider" />
                <p style={{color:'black',fontWeight:'bold',fontSize:'20px'}}>
                Welcome to Ole Brass Rail, your friendly Arcadia sports bar. With almost 40 years of experience, we take pride in serving delicious cocktails and mouth-watering food made from the best ingredients. Our doors are open to you from 6am to 1am every day (2am on Fridays and Saturdays), offering a full breakfast and dinner menu to curb your cravings. Don't miss our karaoke nights every Thursday and Saturday from 8pm to midnight.

We offer some great games all day long with a pool table, Golden Tee Golf, a dart board, and even bingo every Friday night at 7pm. With 21 TVs, you won't find a bad seat in the house for all the biggest games. Game on, drink on, & dine on at OBR.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
                  <div style={{
                    display: "flex",
                    justifyContent: "center"
                  }}>
                    <img src="/assets/img/my/videosection.webp" alt="" />
                  </div>
            </div>
          </div>
        </div>
      </div>



      <TimmerSection />
      <div className='homegallery'>

      {/* <GalleryImages map={7} /> */}
     {/* < HomeGallery/> */}
     <HomeGallery2/>
   

      </div>
      {/* <div onClick={()=>Navigation('/gallery')} class="form-btn" style={{paddingTop:"10px",paddingBottom:'10px',display:'flex',justifyContent:'center'}}>
      <button type="submit" class="btn btn-default btn-submit ">View more..</button></div> */}

    </div>


  )
}

export default WelcomeToClub