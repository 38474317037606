import React, { useState } from 'react'
import UpcommingSlider from '../Home/UpComingSlider/UpcommingSlider'

const TimmerSection = () => {
  // eslint-disable-next-line no-unused-vars
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  return (
    <>
      <div class="event-area inner-padding">
        <div class="event-area-inner">
          <div class="container">
            <div class="row">
              <div class="col-12 foo">
                <div class="section-title-area-2">
                  <h2 class="section-title" style={{color:'black',marginTop:'20px'}}>Upcoming Events</h2>
                  <div class="section-divider"></div>
                  {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incid dunt ut labore et dolore ma.Ut enim ad minim veniam, quis nos</p> */}
                </div>
              </div>
            </div>


         

            {/* slider */}
            <UpcommingSlider />

            <div class="row">
              <div class="col-12">
                {/* <div class="event-btn">
                  <a href="event.html" class="btn btn-default btn-sm-field" role="button">View Moew</a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default TimmerSection