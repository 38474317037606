import React, { useEffect } from 'react'
import { Loader } from './Components/Loader'
import RootApp from './Routes/RootApp'

const App = () => {
  const loader = () => {
    return <Loader />
  }
  useEffect(() => {
    loader()
  }, [])
  return (
    <div>
      <RootApp/>
    </div>
  )
}
export default App