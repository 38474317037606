/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import { Base } from '../Baseurl/Baseurl'
import axios from 'axios'

const Footer = () => {
    const [data, setMapData]= useState([])

    const addressData = () => {
      axios.get(`${Base}/api/user/view/contact`).then((e) => {
        setMapData(e.data.data)
      }).catch((err) => {
        console.log(err.message)
      })
    }
    useEffect(() => {
      addressData()
    }, [])
    return (
        <>
            {/* <!-- Footer Section --> */}
            <footer>
                <div class="footer-area foo" data-sr='bottom'>
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <div class="company-logo">
                                    <img class="" src="./assets/img/logo.png" alt="responsive img"/>
                                </div>
                                <div class="footer-content">
                                    <ul class="address-list">
                                        <li className='d-flex items-center'>
                                            <i class="fa fa-map-marker"></i>
                                            <p>{data?.address ? data?.address : "3738 E Thomas Rd​ Phoenix, AZ. 85018"}</p>
                                        </li>
                                        <li>
                                            <i class="fa fa-phone"></i>
                                            <p>{data?.phone_no ? data?.phone_no : "602- 956-1280"} </p>

                                        </li>
                                        <li>
                                            <i class="fa fa-globe"></i>
                                            <p>{data?.email ? data?.email : "olebrassrail@yahoo.com"}</p>

                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                                <h4 class="footer-title">OPENING TIME</h4>
                                <div class="footer-content">
                                    <div class="opening-time">
                                        <p style={{display:"flex",marginBottom:"0px"}}><p>Monday-Sunday: </p> <small style={{width:"50%"}}>6:00AM to 1:00AM</small></p>
                                        <p style={{display:"flex",padding:"10px 0px 10px"}}>Kitchen Hours: </p> 
                                     
                                        <p style={{display:"flex"}}><p> Sunday-Thursday:</p> <small style={{width:"50%"}}>6:00AM to 10PM</small></p>
                                        <p style={{display:"flex",marginTop:"0px"}}><p>Friday and Saturday: </p> <small style={{width:"60%"}}>6:00AM to Midnight</small></p>
                                    </div>
                                </div>
                            </div>
                      
                            {/* <div class="col-12 col-sm-6 col-md-6 col-lg-3">
                                <h4 class="footer-title">TWITTER FEED</h4>
                                <div class="footer-content">
                                    <ul class="twitter-list">
                                        <li>
                                            <p>Lorem ipsum dolor sit am ectet adip isicing elit, sed eiusmod.</p>
                                            <span class="twitt-time">5 hours Ago</span>
                                        </li>
                                        <li>
                                            <p>Lorem ipsum dolor sit am ectet adip isicing elit, sed eiusmod.</p>
                                            <span class="twitt-time">7 hours Ago</span>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
                            {/* <div class="col-12 col-sm-6 col-md-6 col-lg-3">
                                <h4 class="footer-title">Newsletters</h4>
                                <div class="footer-content">
                                    <div class="footer-form">
                                        <p>Lorem ipsum dolor sit amet, cons ectetur adip isicing elit, sed eiusmod.</p>
                                        <form action="#" method="POST">
                                            <div class="form-group">
                                                <label for="email2">Email Address...</label>
                                                <div class="input-group">
                                                    <input type="text" placeholder="Email Address..." class="form-control2" id="email2" />
                                                </div>
                                            </div>
                                            <div class="form-btn">
                                                <button type="submit" class="btn btn-default btn-submit">Subscribe</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </footer>
            {/* <!-- Ends Footer Section --> */}
            {/* <!-- Copyright Section --> */}
            <div class="copyright-area">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <p class="footer-copyright">&copy; Ole Brass Rail, 2024. Made with ❤️ by <a href="http://hnhsofttechsolutions.com/" target="_blank">HNH Soft Tech Solutions.</a></p>
                        </div>
                        <div class="col-12 col-md-6">
                            <ul class="footer-social-link">
                                <li><a href="https://www.facebook.com/OleBrassRail" target='_blank'><i class="fa fa-facebook"></i></a></li>
                                <li><a href="https://www.instagram.com/olebrassrailaz/" target='_blank'><i class="fa fa-instagram"></i></a></li>
                                {/* <li><a href="#"><i class="fa fa-rss"></i></a></li>
                                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer