import Banner from '../../Components/Banner'
import BreakFastSlider from '../../Components/BreakFast/BreakFastSlider'
import HomebannerNew from '../../Components/Home/HomebannerNew'
import Layout from '../../Layout/Layout'

const BreakFast = () => {
  return (
 <Layout>
  <HomebannerNew/>
<Banner title={"breakfast menu"}/>
<BreakFastSlider/>
 </Layout>
  )
}

export default BreakFast