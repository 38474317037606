/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './style.css';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
    // const navigate = useNavigate();
    const { pathname } = useLocation();
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) { // Adjust the scroll value as needed
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                width: '100%',
                zIndex: 1000,
                color: isScrolled ? 'white !important' : 'black !important',
                backgroundColor: isScrolled ? 'rgba(0, 0, 0, 0.5)' : 'transparent', // Change color here
                transition: 'background-color 0.3s ease-in-out',
            }}
        >
            <Navbar expand="lg" style={{
                backgroundColor: 'transparent',
                maxWidth: '100%',
            }} className="container m-auto">
                <Container fluid>
                    {/* <img
                        src="./assets/img/logo.png"
                        alt=""
                        style={{ width: '130px', cursor: 'pointer' }}
                        onClick={() => navigate('/')}
                    /> */}
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="m-auto my-2 my-lg-0 NavBarItems"
                            style={{
                                maxHeight: '180px',
                                color: isScrolled ? 'white' : 'black',

                            }}
                            navbarScroll
                        >
                            <Link
                                style={{
                                    color: isScrolled ? 'white' : 'black',
                                }}
                                className={pathname === '/' ? 'Nav_Link active' : 'Nav_Linkplan'}
                                to="/"
                            >
                                Home
                            </Link>
                            <Link
                                style={{
                                    color: isScrolled ? 'white' : 'black',
                                }}
                                className={pathname === '/events' ? 'Nav_Link active' : 'Nav_Linkplan'}
                                to="/events"
                            >
                                Upcoming Events
                            </Link>
                            <Link
                                style={{
                                    color: isScrolled ? 'white' : 'black',
                                }}
                                className={pathname === '/gallery' ? 'Nav_Link active' : 'Nav_Linkplan'}
                                to="/gallery"
                            >
                                Gallery
                            </Link>
                            <Link
                                style={{
                                    color: isScrolled ? 'white' : 'black',
                                }}
                                className={pathname === '/breakfast_menu' ? 'Nav_Link active' : 'Nav_Linkplan'}
                                to="/breakfast_menu"
                            >
                                Breakfast Menu
                            </Link>
                            <Link
                                style={{
                                    color: isScrolled ? 'white' : 'black',
                                }}
                                className={pathname === '/lunch_dinner' ? 'Nav_Link active' : 'Nav_Linkplan'}
                                to="/lunch_dinner"
                            >
                                Lunch Dinner Menu
                            </Link>
                            <Link
                                style={{
                                    color: isScrolled ? 'white' : 'black',
                                }}
                                className={pathname === '/daily_specials' ? 'Nav_Link active' : 'Nav_Linkplan'}
                                to="/daily_specials"
                            >
                                Daily Specials
                            </Link>
                            <Link
                                style={{
                                    color: isScrolled ? 'white' : 'black',
                                }}
                                className={pathname === '/contact_us' ? 'Nav_Link active' : 'Nav_Linkplan'}
                                to="/contact_us"
                            >
                                Hours/Contact/Map
                            </Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
};
export default Header;
