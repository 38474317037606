import Banner from "../../Components/Banner"
import HomebannerNew from "../../Components/Home/HomebannerNew"
import LunchDinnerSlider from "../../Components/LunchDinner/LunchDinnerSlider"
import Layout from "../../Layout/Layout"

const LunchDinner = () => {
  return (
    <Layout>
      <HomebannerNew/>
<Banner title={"lunch dinner menu"} />
<LunchDinnerSlider/>
    </Layout>
  )
}

export default LunchDinner