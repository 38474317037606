import HomebannerNew from '../../Components/Home/HomebannerNew'
import WelcomeToClub from '../../Components/Home/WelcomeToClub'
import Layout from '../../Layout/Layout'
export const Home = () => {
    return (
        <Layout>
            {/* <HomeBanner /> */}
            <HomebannerNew/>
            <WelcomeToClub/>
        </Layout>
    )
}
